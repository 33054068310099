import React, { useContext, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

export const ForumDetail = () => {
	const { id } = useParams();
	const { user } = useContext(AuthContext);
	const [forum, setForum] = useState();

	const getForumDetail = async () => {
		let response = await fetch(`https://animeplay.xyz/api/forums/?id=${id}`);

		if (response.ok) {
			let data = await response.json();

			setForum(data);
		}
	};

	useEffect(() => {
		getForumDetail();
	}, []);
	return (
		<section
			style={{
				maxWidth: "1140px",
				margin: "100px auto",
				color: "#fff",
				padding: "15px 20px",
			}}
		>
			<h2
				style={{
					textAlign: "center",
				}}
			>
				{forum?.title}
			</h2>
			<div
				style={{
					padding: "12px",
					border: "0.5px solid #fff",
					display: "flex",
					gap: "20px",
					minHeight: "70vh",
				}}
			>
				<div
					className="participants"
					style={{
						width: "20%",
						borderRight: "0.5px solid #fff",
						paddingRight: "12px",
						display: "flex",
						flexDirection: "column",
						gap: "5px",
					}}
				>
					<span
						style={{
							display: "block",
							textAlign: "center",
							marginBottom: "10px",
						}}
					>
						Participants: {forum?.participants_id.length}
					</span>
					{forum?.participants.map((participant) => {
						return (
							<div
								key={participant.id}
								className="participants_item"
								style={{}}
							>
								{" "}
								{/* <Link
									to={`/user/${user.username}/${user.id}`}
									style={{
										textDecoration: "none",
										color: "#fff",
									}}
								>
									{forum.author.username}
								</Link> */}
								<Link
									to={`/user/${participant.username}/${participant.id}`}
									style={{
										textDecoration: "none",
									}}
								>
									<div
										style={{
											display: "flex",
											gap: "10px",
											alignItems: "center",
										}}
									>
										<div
											style={{
												width: "50px",
												height: "50px",
												backgroundImage: `url(https://animeplay.xyz${participant.picture})`,
												backgroundPosition: "center",
												backgroundSize: "cover",
												borderRadius: "50%",
											}}
										></div>
										<span
											style={{
												color: "#fff",
											}}
										>
											{participant.username}{" "}
											{participant.id == user.id ? <>(you)</> : <></>}
										</span>
									</div>
								</Link>
							</div>
						);
					})}
				</div>
				<div
					className="chat"
					style={{
						width: "80%",
					}}
				>
					test
				</div>
			</div>
			{forum?.participants_id.includes(user.id) ? (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						gap: "10px",
						marginTop: "10px",
					}}
				>
					<input
						style={{
							outline: "none",
							padding: "6px 8px",
							width: "100%",
							border: "0.5px solid #fff",
						}}
						type="text"
						name="review"
						className="transparent"
						id="review"
					/>
					<img
						src="../paper-plane.png"
						alt=""
						style={{
							width: "30px",
							height: "25px",
							cursor: "pointer",
						}}
						onClick={() => {}}
					/>
				</div>
			) : (
				<div
					style={{
						display: "flex",
						gap: "10px",
						marginTop: "10px",
						justifyContent: "center",
						fontSize: "18px",
					}}
				>
					<Link
						style={{
							display: "flex",
							gap: "5px",
							alignItems: "center",
							justifyContent: "center",
							cursor: "pointer",
							padding: "4px 10px",
							border: "1px solid #fff",
							textDecoration: "none",
							color: "#fff",
						}}
						onClick={() => {
							// setStatus("create");
						}}
						to={`/forum/${id}`}
					>
						JOIN
					</Link>
					<div
						style={{
							display: "flex",
							gap: "5px",
							alignItems: "center",
							justifyContent: "center",
							cursor: "pointer",
							padding: "4px 10px",
							border: "1px solid #fff",
							// width: "75px",
						}}
						onClick={() => {
							// setStatus("create");
						}}
					>
						REPORT
					</div>
				</div>
			)}
		</section>
	);
};
