import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { Link } from "react-router-dom";

export const Forum = () => {
	const { user } = useContext(AuthContext);
	const [status, setStatus] = useState("main");
	const [forums, setForums] = useState([]);
	const [foundForums, setFoundForums] = useState([]);
	const [query, setQuery] = useState("");
	const [category, setCategory] = useState("main");

	let getForums = async (id) => {
		let response = await fetch(`https://animeplay.xyz/api/forums/?id=${id}`);

		if (response.ok) {
			let data = await response.json();
			setForums(data);
		}
	};
	let getForumsByUser = async () => {
		let response = await fetch(
			`https://animeplay.xyz/api/forums/?user=${user?.id}`
		);

		if (response.ok) {
			let data = await response.json();
			setForums(data);
		}
	};

	let sendData = async (e) => {
		e.preventDefault();
		// console.log("Form submitted");
		let formData = new FormData();
		formData.append("title", e.target.title.value);
		formData.append("author", user?.id);
		let fileInput = e.target.picture;
		if (fileInput.files.length > 0) {
			formData.append("picture", fileInput.files[0]);
		}

		let response = await fetch(`https://animeplay.xyz/api/forum-create/`, {
			method: "POST",
			body: formData,
		});
		let data = await response.json();

		if (response.status === 201) {
			setStatus("main");
		}
	};

	const checkForums = async (query) => {
		let response = await fetch(`https://animeplay.xyz/api/forums/?q=${query}`);

		if (response.ok) {
			let data = await response.json();

			setFoundForums(data);
		}
	};

	const handleChange = (e) => {
		const value = e.target.value;

		if (value.length > 2) {
			setQuery(value);
			checkForums(value);
		} else if (value.length == 0) {
			setFoundForums([]);
		}
	};

	useEffect(() => {
		let mainElement = document.getElementById("main");
		let my = document.getElementById("my");
		if (category == "main") {
			getForums("all");
			mainElement?.classList.add("active");
			my?.classList.remove("active");
		} else if (category == "my") {
			getForumsByUser();
			mainElement?.classList.remove("active");
			my?.classList.add("active");
		}
	}, [category, status]);
	return (
		<section
			className="forum__container"
			style={{
				maxWidth: "1140px",
				margin: "100px auto 0 auto",
				color: "#fff",
				padding: "20px",
			}}
		>
			<h2
				style={{
					textAlign: "center",
				}}
			>
				Coming soon!
			</h2>
			{/* <ul
				className="categories__list"
				style={{
					marginBottom: "10px",
				}}
			>
				<li className="categories__list-item" id="main">
					<Link
						onClick={() => {
							setCategory("main");
						}}
					>
						Main
					</Link>
				</li>
				<li className="categories__list-item" id="my">
					<Link
						onClick={() => {
							setCategory("my");
						}}
					>
						My discussions
					</Link>
				</li>
			</ul> */}
{/* 
			{category == "main" ? (
				<>
					{status == "main" ? (
						<>
							<div
								style={{
									display: "flex",
									gap: "10px",
								}}
							>
								<div
									style={{
										display: "flex",
										gap: "5px",
										alignItems: "center",
										justifyContent: "center",
										cursor: "pointer",
										padding: "8px 10px",
										border: "1px solid #fff",
										width: "170px",
										margin: "20px auto 0 auto",
									}}
									onClick={() => {
										setStatus("create");
									}}
								>
									Create discussion
								</div>
								<div
									style={{
										display: "flex",
										gap: "5px",
										alignItems: "center",
										justifyContent: "center",
										cursor: "pointer",
										padding: "8px 10px",
										border: "1px solid #fff",
										width: "170px",
										margin: "20px auto 0 auto",
									}}
									onClick={() => {
										setStatus("search");
									}}
								>
									Search
								</div>
							</div>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									gap: "10px",
									marginTop: "20px",
								}}
							>
								{forums.length != 0 ? (
									<>
										{forums?.map((forum) => {
											return (
												<div
													style={{
														display: "flex",
														gap: "20px",
														padding: "12px",
														border: "1px solid #fff",
														// width: "625px",
													}}
												>
													{forum.picture ? (
														<Link to={`/forum/${forum.id}`}>
															<div
																onClick={() => {}}
																className="movies__container-item"
																style={{
																	backgroundImage: `url(https://animeplay.xyz${forum.picture})`,
																	position: "relative",
																	transition: "0.5s",
																	transform: "none",
																	color: "#fff",
																	opacity: "unset",
																	cursor: "pointer",
																	width: "400px",
																	height: "240px",
																	"&:hover": {
																		transform: "none",
																		cursor: "pointer",
																		opacity: "unset",
																	},
																}}
															></div>
														</Link>
													) : (
														<div
															onClick={() => {}}
															className="movies__container-item"
															style={{
																backgroundImage: `/not-found.png`,
																position: "relative",
																transition: "0.5s",
																transform: "none",
																color: "#fff",
																opacity: "unset",
																cursor: "pointer",
																width: "180px",
																height: "240px",
																"&:hover": {
																	transform: "none",
																	cursor: "pointer",
																	opacity: "unset",
																},
															}}
														></div>
													)}

													<div
														key={forum.id}
														style={{
															display: "flex",
															flexDirection: "column",
															gap: "10px",
															position: "relative",
															justifyContent: "center",
														}}
													>
														<span>Title: {forum.title}</span>
														<span>
															Author:{" "}
															<Link
																to={`/user/${forum.author.username}/${forum.author.id}`}
																style={{
																	textDecoration: "underline",
																	color: "#fff",
																}}
															>
																{forum.author.username}
															</Link>
														</span>

														<span>
															Participants: {forum.participants.length}
														</span>
														<span>Created: {forum.created}</span>
														<div
															style={{
																display: "flex",
																gap: "10px",
															}}
														>
															<Link
																style={{
																	display: "flex",
																	gap: "5px",
																	alignItems: "center",
																	justifyContent: "center",
																	cursor: "pointer",
																	padding: "4px 10px",
																	border: "1px solid #fff",
																	width: "75px",
																	textDecoration: "none",
																	color: "#fff",
																}}
																onClick={() => {
																	// setStatus("create");
																}}
																to={`/forum/${forum.id}`}
															>
																JOIN
															</Link>
															<div
																style={{
																	display: "flex",
																	gap: "5px",
																	alignItems: "center",
																	justifyContent: "center",
																	cursor: "pointer",
																	padding: "4px 10px",
																	border: "1px solid #fff",
																	width: "75px",
																}}
																onClick={() => {
																	// setStatus("create");
																}}
															>
																REPORT
															</div>
														</div>
													</div>
												</div>
											);
										})}
									</>
								) : (
									<h3
										style={{
											textAlign: "center",
										}}
									>
										No discussions yet
									</h3>
								)}
							</div>
						</>
					) : (
						<> </>
					)}{" "}
					{status == "create" ? (
						<>
							{" "}
							<form
								style={{
									maxWidth: "600px",
									margin: "0 auto",
									display: "flex",
									flexDirection: "column",
									gap: "20px",
								}}
								onSubmit={sendData}
								encType="multipart/form-data"
							>
								<h2
									style={{
										textAlign: "center",
									}}
								>
									Create your own discussion room
								</h2>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
									}}
								>
									<label htmlFor="title">Topic:</label>
									<input
										type="text"
										style={{
											outline: "none",
											padding: "6px 8px",
											background: "transparent",
											border: "1px solid #fff",
											color: "#fff",
										}}
										required
										name="title"
										placeholder="You may enter the question or just your thoughts u want to share"
									/>
								</div>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										gap: "5px",
									}}
								>
									<label htmlFor="picture">Select picture:</label>
									<input
										style={{
											width: "100%",
										}}
										type="file"
										name="picture"
										id="picture"
										accept="image/*"
									/>
									<span>*optional</span>
								</div>
								<div
									style={{
										display: "flex",
										gap: "20px",
										marginTop: "30px",
										justifyContent: "center",
									}}
								>
									<input
										style={{
											display: "flex",
											gap: "5px",
											alignItems: "center",
											justifyContent: "center",
											cursor: "pointer",
											padding: "8px 10px",
											border: "1px solid #fff",
											background: "transparent",
											color: "#fff",
											width: "70px",
										}}
										type="submit"
										value="Save"
									/>

									<div
										style={{
											display: "flex",
											gap: "5px",
											alignItems: "center",
											justifyContent: "center",
											cursor: "pointer",
											padding: "8px 10px",
											border: "1px solid #fff",
										}}
										onClick={() => {
											setStatus("main");
										}}
									>
										Cancel
									</div>
								</div>
							</form>
						</>
					) : (
						<></>
					)}
					{status == "search" ? (
						<section className="search">
							<div
								style={{
									display: "flex",
									gap: "5px",
									alignItems: "center",
									justifyContent: "center",
									cursor: "pointer",
									padding: "4px 10px",
									border: "1px solid #fff",
									width: "100px",
								}}
								onClick={() => {
									setStatus("main");
								}}
							>
								BACK
							</div>
							<h4
								style={{
									margin: "10px 0",
								}}
							>
								Search...
							</h4>
							<input
								style={{
									width: "100%",
									background: "transparent",
									border: "1px solid #fff",
									color: "#fff",
								}}
								type="text"
								className="search__item"
								onChange={handleChange}
								placeholder="title of the discussion..."
							/>
							<div>
								<span style={{}}>results: {foundForums.length}</span>
								{foundForums.length != 0 ? (
									<>
										{foundForums?.map((forum) => {
											return (
												<div
													style={{
														display: "flex",
														gap: "20px",
														padding: "12px",
														border: "1px solid #fff",
														alignItems: "center",
														// width: "625px",
													}}
												>
													{forum.picture ? (
														<Link to={`/forum/${forum.id}`}>
															<div
																onClick={() => {}}
																className="movies__container-item"
																style={{
																	backgroundImage: `url(https://animeplay.xyz${forum.picture})`,
																	position: "relative",
																	transition: "0.5s",
																	transform: "none",
																	color: "#fff",
																	opacity: "unset",
																	cursor: "pointer",
																	width: "400px",
																	height: "240px",
																	"&:hover": {
																		transform: "none",
																		cursor: "pointer",
																		opacity: "unset",
																	},
																}}
															></div>
														</Link>
													) : (
														<div
															onClick={() => {}}
															className="movies__container-item"
															style={{
																backgroundImage: `/not-found.png`,
																position: "relative",
																transition: "0.5s",
																transform: "none",
																color: "#fff",
																opacity: "unset",
																cursor: "pointer",
																width: "180px",
																height: "240px",
																"&:hover": {
																	transform: "none",
																	cursor: "pointer",
																	opacity: "unset",
																},
															}}
														></div>
													)}

													<div
														key={forum.id}
														style={{
															display: "flex",
															flexDirection: "column",
															gap: "10px",
															position: "relative",
														}}
													>
														<span>Title: {forum.title}</span>
														<span>
															Author:{" "}
															<Link
																to={`/user/${forum.author.username}/${forum.author.id}`}
																style={{
																	textDecoration: "underline",
																	color: "#fff",
																}}
															>
																{forum.author.username}
															</Link>
														</span>

														<span>
															Participants: {forum.participants.length}
														</span>
														<span>Created: {forum.created}</span>
														<div
															style={{
																display: "flex",
																gap: "10px",
															}}
														>
															<Link
																style={{
																	display: "flex",
																	gap: "5px",
																	alignItems: "center",
																	justifyContent: "center",
																	cursor: "pointer",
																	padding: "4px 10px",
																	border: "1px solid #fff",
																	width: "75px",
																	textDecoration: "none",
																	color: "#fff",
																}}
																onClick={() => {
																	// setStatus("create");
																}}
																to={`/forum/${forum.id}`}
															>
																JOIN
															</Link>
															<div
																style={{
																	display: "flex",
																	gap: "5px",
																	alignItems: "center",
																	justifyContent: "center",
																	cursor: "pointer",
																	padding: "4px 10px",
																	border: "1px solid #fff",
																	width: "75px",
																}}
																onClick={() => {
																	// setStatus("create");
																}}
															>
																REPORT
															</div>
														</div>
													</div>
												</div>
											);
										})}
									</>
								) : (
									<span></span>
								)}
							</div>
						</section>
					) : (
						<></>
					)}
				</>
			) : (
				<></>
			)} */}

			{/* {category == "my" ? (
				<>
					{" "}
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: "10px",
							marginTop: "20px",
						}}
					>
						{forums.length != 0 ? (
							<>
								{forums?.map((forum) => {
									return (
										<div
											key={forum.id}
											style={{
												padding: "12px",
												border: "1px solid #fff",
												display: "flex",
												flexDirection: "column",
												gap: "10px",
												position: "relative",
												justifyContent: "center",
											}}
										>
											<span>Title: {forum.title}</span>
											<span>
												Author:{" "}
												<Link
													to={`/user/${forum.author.username}/${forum.author.id}`}
													style={{
														textDecoration: "underline",
														color: "#fff",
													}}
												>
													{forum.author.username}
												</Link>
											</span>

											<span>Participants: {forum.participants.length}</span>
											<span>Created: {forum.created}</span>
											<div
												style={{
													display: "flex",
													gap: "10px",
													alignItems: "center",
												}}
											>
												<div
													style={{
														display: "flex",
														gap: "5px",
														alignItems: "center",
														justifyContent: "center",
														cursor: "pointer",
														padding: "4px 10px",
														border: "1px solid #fff",
														width: "75px",
													}}
													onClick={() => {
														// setStatus("create");
													}}
												>
													DELETE
												</div>
											</div>
										</div>
									);
								})}
							</>
						) : (
							<h3
								style={{
									textAlign: "center",
								}}
							>
								No discussions yet
							</h3>
						)}
					</div>
				</>
			) : (
				<></>
			)} */}
		</section>
	);
};
